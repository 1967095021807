import app        from 'flarum/app';
import Page       from 'flarum/components/Page';
import LogInModal from 'flarum/components/LogInModal';

export default class LoginPage extends Page {
    init() {
        super.init();

        m.route('/');

        app.session.user || app.modal.show(new LogInModal);
    }

    view() {
        return m('div');
    }
}
