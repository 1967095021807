import {extend}         from 'flarum/extend';
import app              from 'flarum/app';
import ForumApplication from 'flarum/ForumApplication';
import SettingsPage     from 'flarum/components/SettingsPage';
// import IndexPage        from 'flarum/components/IndexPage';
// import LogInModal       from 'flarum/components/LogInModal';
import LoginPage        from './components/LoginPage';

app.initializers.add('upmore/flarum-ext-ptchp', () => {

    //// needs to define routes on backend as well
    app.routes.upmoreDirectLinksLogin = {
        path: '/login',
        component: LoginPage.component()
    };

    extend(ForumApplication.prototype, 'mount', function () {
        if (app.session.user) $('body').addClass('logged-in')
    })

    // extend(IndexPage.prototype, 'init', function () {
    //     const user = app.session.user;
    //
    //     if (!user && location.hash === '#login') {
    //         app.modal.show(new LogInModal)
    //     }
    // })

    extend(SettingsPage.prototype, 'accountItems', function (items) {
        const user = this.user;

        // hide changeEmail from not activated users
        if (! user.isEmailConfirmed()) {
            items.remove('changeEmail')
        }
    });
});
